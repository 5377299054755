import React from 'react';

const Footer = () => {
    return (
        <footer className="bottom-0 bg-gray-700 text-white">
            <div className="container mx-auto flex justify-between p-4">
                <div className="">
                    <div className="mr-2 ">Antero Fashion</div>
                    <div className="">Krekelput 1 9700 Oudenaarde</div>
                    <a href='mailto:info@antero.be' className="cursor-pointer">info@antero.be</a>
                </div>
                <div>

                    <a href="https://www.facebook.com/pages/Fashion-Antero/190357951013532?ref=hl" target="_blank">
                        <i className="fab fa-facebook ml-4 mr-4 text-24"></i>
                    </a>
                    <a href="http://www.twitter.com/fashionantero" target="_blank">
                        <i className="fab fa-twitter text-24"></i>
                    </a>
                    <div className="mb-2">BE0833.401.927</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
