import React from 'react';

const Terms = () => {
    return (
        <div>
            <h2 className="mb-4">Algemene voorwaarden:</h2>

            <p className="mb-2">Volgende websites www.antero.be en www.box.antero.be zijn volledige eigendom van Fashion Antero, krekelput 1, 9700 Oudenaarde. Vertegenwoordigd door Teun Dhondt, ingeschreven in het
                handelsregister met volgende BTW-nummer: BE0833.401.927.</p>

            <p className="mb-2">Fashion Antero behoudt zich het recht ten allen tijden binnen de toegestane wet, veranderingen aan te brengen op de websites, alsook de algemene voorwaarden. Alle diensten vinden
                uitsluitend plaats volgens deze algemene voorwaarden, hierdoor is Fashion Antero gerechtigd éénzijdig de contractuele relatie met de klant te wijzigen, voor zover nodig.</p>

            <p className="mb-2">Bij contactinformatie kan de klant zijn persoonlijke gegevens invullen, dit is noodzakelijk om gebruik te maken van onze diensten en is volkomen gratis. De klant is alsook verplicht om
                correcte en hedendaagse informatie te verstrekken, deze persoonlijke gegevens worden door Fashion Antero bewaard. Deze worden nooit aan derden verstrekt.</p>

            <p className="mb-2">De klant geeft aan Fashion Antero informatie over zijn stijl, smaak, leeftijd en budget. Volgens deze informatie kunnen wij een bijpassende box samenstellen op maat. Bij de bestelling
                geeft de klant eenzijdig beschikkingsrecht van de prestaties en geeft Fashion Antero het recht om naar eigen keuze de box samen te stellen. De klant heeft geen recht op aanvaarding van
                de overeenkomst.</p>
            <p className="mb-2">De klant heeft het recht om binnen een termijn van 14 dagen, de aangekochte goederen te retourneren op eigen kosten naar: Fashion Antero, krekelput 1, 9700 Oudenaarde. Dit moet altijd
                voorafgegaan worden met een verklaring om u overeenkomst te herroepen via email: info@antero.be. Binnen een termijn van 14 dagen wordt het bedrag van de teruggestuurde goederen
                teruggestort, op rekening van de klant die oorspronkelijk gebruikt werd voor de betaling.</p>

            <p className="mb-2">www.antero.be, als www.box.antero.be zijn beschermd, met andere woorden het is verboden om inhoud van beide websites te kopiëren of dit te gebruiken als kopie.</p>

            <p className="mb-2">De box wordt uitsluitend persoonlijk geleverd op het adres dat is opgegeven bij de contactinformatie. Dit enkel en alleen door op voorhand telefonisch contact te hebben gehad over datum
                en uur voor levering. Eenmaal aangekomen is de klant verplicht om het afleveringsbewijs² te handtekenen en krijgt de klant 30 minuten tijd om binnenshuis te passen, hierna worden de
                artikelen die goed bevonden worden door de klant afgerekend, de overige stuks worden integraal teruggenomen. Mocht er geen enkel artikel goed bevonden worden, worden geen kosten in
                rekening gebracht. Wij werken nooit samen met een externe transporteur, wij werken enkel en alleen lokaal en persoonlijk. (² lijst van alle artikelen aanwezig in de box)</p>

            <p className="mb-2">De betaling kan enkel ter plaatse via cash of payconiq. Mocht de betaling niet onmiddellijk kunnen plaatsvinden, blijft Fashion Antero eigenaar van de goederen en worden alle artikelen
                terug meegenomen. Er kan niet aangekocht worden op rekening.</p>

            <p className="mb-2">Fashion Antero kan niet aansprakelijk gesteld worden voor een niet juiste levering, noch op het juiste tijdstip of datum, vertragingen kunnen ten alle tijden optreden. Alsook voor
                schade of belemmering van de prestaties kunnen we niet aansprakelijk gesteld worden.</p>

            <p className="mb-2">De goederen blijven eigendom van Fashion Antero, totdat de volledige betaling van de artikelen die goed bevonden werden is afgerond ter plaatse. Hierbij wordt het afleveringsbewijs door
                beide partijen ondertekend en is de verkoop officieel gesloten.</p>
        </div>
    );
};

export default Terms;
