import React, {useReducer, useState} from 'react';

import {
    boxAssignBrandHandler,
    boxAssignStyleHandler,
    boxRemoveBrandHandler,
    boxRemoveStyleHandler,
    boxAssignTypeHandler,
    boxRemoveTypeHandler,
    boxAssignTypeDislikeHandler,
    boxRemoveTypeDislikeHandler, boxAssignAgeHandler, boxAssignSizeHandler, boxRemoveSizeHandler, boxAssignBoxSizeHandler, boxAssignBoxContactFieldHandler
} from "./actions";
import {dataReducer} from "./reducers";
import data from "./data";
import {send} from "emailjs-com";
import {mailJsService, mailJsTemplate, mailJsUserId} from "../config";
import {useHistory} from "react-router-dom";

const defaultContext = {
    styles: [],
    types: [],
    typesDislike: [],
    brands: [],
    age: undefined,
    boxSize: undefined,
    sizes: [],
    contact: {
        firstName: undefined,
        lastName: undefined,
        street: undefined,
        number: undefined,
        postal: undefined,
        city: undefined,
        telephone: undefined,
        email: undefined,
    }

};
const DataContext = React.createContext(defaultContext);

const DataContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(dataReducer, defaultContext, undefined);
    const [boxStep, setBoxStep] = useState(0)
    let history = useHistory();

    const sendBoxMail = (cb) => {
        var params = {
            firstName: state.contact.firstName,
            lastName: state.contact.lastName,
            street: state.contact.street,
            number: state.contact.number,
            city: state.contact.city,
            telephone: state.contact.telephone,

            styles: state.styles,
            types: state.types,
            typesDislike: state.typesDislike,
            brands: state.brands,
            age: state.age,
            sizes: state.sizes,
            boxSize: state.boxSize,
            reply_to: state.contact.email,
        }


        send(mailJsService, mailJsTemplate, params, mailJsUserId)
            .then(function (response) {

                cb('ok')
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                cb('nok')
                console.log('FAILED...', error);
            });
    }


    return (
        <DataContext.Provider value={{
            data: data,
            box: state,
            boxStep: boxStep,
            dispatch,

            assignBoxStyle: (key) => boxAssignStyleHandler(dispatch, key),
            removeBoxStyle: (key) => boxRemoveStyleHandler(dispatch, key),

            assignBoxType: (key) => boxAssignTypeHandler(dispatch, key),
            removeBoxType: (key) => boxRemoveTypeHandler(dispatch, key),

            assignBoxTypeDislike: (key) => boxAssignTypeDislikeHandler(dispatch, key),
            removeBoxTypeDislike: (key) => boxRemoveTypeDislikeHandler(dispatch, key),

            assignBoxBrand: (key) => boxAssignBrandHandler(dispatch, key),
            removeBoxBrand: (key) => boxRemoveBrandHandler(dispatch, key),

            assignBoxAge: (key) => boxAssignAgeHandler(dispatch, key),

            assignBoxSize: (key) => boxAssignSizeHandler(dispatch, key),
            removeBoxSize: (key) => boxRemoveSizeHandler(dispatch, key),

            assignBoxBoxSize: (key) => boxAssignBoxSizeHandler(dispatch, key),

            setBoxStep: (step) => setBoxStep((step)),

            assignBoxContactField: (field, value) => boxAssignBoxContactFieldHandler(dispatch, field, value),

            sendMail: (cb) => sendBoxMail(cb),
        }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;

export {DataContextProvider};
