import React, {useContext} from 'react';
import DataContext from "../../../data/context";

const WizardButtons = ({validator, onComplete}) => {
    const {boxStep, setBoxStep} = useContext(DataContext);

    const handleNext = () => {
        if (validator()) {
            if (boxStep < 7) {
                setBoxStep(boxStep + 1)
            } else {
                onComplete()
            }
        }
    }

    const handlePrevious = () => {
        setBoxStep(boxStep - 1)
    }

    return (
        <div className="flex justify-end  mt-4">

            {boxStep > 0 &&
            <div className="border border-primary text-primary cursor-pointer w-30 px-4 py-2 mr-2 "
                 onClick={handlePrevious}>
                Vorige
            </div>}

            <div className="border border-primary text-primary hover:bg-primary hover:text-white w-30 px-4 py-2 cursor-pointer"
                 onClick={handleNext}>
                {(boxStep < 7) ? 'Volgende' : 'Verzenden'}
            </div>
        </div>
    );
};

export default WizardButtons;
