import React, {useContext, useEffect, useState} from 'react';
import Style from "./components/Style";
import Brand from "./components/Brand";

import BoxSize from "./components/BoxSize";
import Identification from "./components/Identification";
import {send} from "emailjs-com";
import {mailJsService, mailJsTemplate, mailJsUserId} from "../../config";
import Types from "./components/Types";
import DataContext from "../../data/context";
import TypesDislike from "./components/TypesDislike";
import Age from "./components/Age";
import Size from "./components/Size";


const AnteroBoxWizard = () => {
    const {box, data, boxStep} = useContext(DataContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [boxStep]);

    const getCurrentStepComponent = (step) => {
        switch (step) {

            case 1:
                return <Types/>
            case 2:
                return <TypesDislike/>
            case 3:
                return <Brand/>
            case 4:
                return <Age/>
            case 5:
                return <Size/>
            case 6:
                return <BoxSize/>
            case 7:
                return <Identification/>
            default:

                return <Style/>
        }
    }

    return (
        <div className="">
            {getCurrentStepComponent(boxStep)}
        </div>
    );
};

export default AnteroBoxWizard;
