import React, {useContext, useEffect, useState} from 'react';
import {find, flatten, includes, map, uniq} from "lodash";
import DataContext from "../../../data/context";
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const Types = () => {
    const {box, data, assignBoxType, removeBoxType} = useContext(DataContext);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Types'
        });
    }, []);

    const handleClick = (key) => {
        if (!includes(box.types, key)) {
            assignBoxType(key)
        } else {
            removeBoxType(key)
        }
    }

    const validator = () => {
        if (box.types.length === 0) {
            setErrors("Gelieve ten minste 1 type kledij te selecteren")
        }

        return box.types.length > 0
    }

    const typeOptions = uniq(flatten(map(box.styles, (selectedStyle) => {
        const configStyle = find(data.configuration.styles, (style) => style.key === selectedStyle);
        return configStyle.types
    })));

    return (
        <WizardContainer title="Waar verlangt je kledingkast naar?" errors={errors} validator={validator}>
            <div className=" grid grid-cols-2  md:grid-cols-5 gap-2 md:gap-4">
                {map(typeOptions, (key, i) => {
                    const type = data.types[key]

                    return (
                        <div key={i}
                             className={`md:w-auto flex-col cursor-pointer relative border border-1 hover:border-primary hover:opacity-100 ${includes(box.types, type.key) ? 'border-primary' : 'opacity-90'}`}

                             onClick={() => handleClick(type.key)}>
                            <div>
                                <img src={type.image}/>
                            </div>
                            <div className={`flex bg-primary items-center justify-center text-white p-2 truncate`}>
                                {type.label}
                            </div>

                            {includes(box.types, type.key) &&
                            <div className=" flex items-center justify-center absolute  top-0 right-0 mt-2 mr-2 bg-primary border border-white rounded-full text-white" style={{width: 30, height: 30}}>
                                <i
                                    className="fas fa-check" style={{fontSize: 14}}></i></div>}
                        </div>
                    )
                })}
            </div>
        </WizardContainer>

    );
};

export default Types;
