import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {find, flatten, includes, map, uniq} from "lodash";
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const Brand = () => {
    const {box, data, assignBoxBrand, removeBoxBrand} = useContext(DataContext);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Brand'
        });
    }, []);

    const handleClick = (key) => {
        if (!includes(box.brands, key)) {
            assignBoxBrand(key)
        } else {
            removeBoxBrand(key)
        }
    }

    const validator = () => {
        if (box.brands.length === 0) {
            setErrors("Gelieve ten minste 1 merk te selecteren")
        }

        return box.brands.length > 0
    }

    const brandOptions = uniq(flatten(map(box.types, (selectedType) => {
        const configType = data.types[selectedType];
        return configType.brands
    })));

    return (
        <WizardContainer title="Welke merken heb je graag?" errors={errors} validator={validator}>
            <div className=" grid grid-cols-2  md:grid-cols-4 gap-2 md:gap-4">
                {map(brandOptions, (key, i) => {
                    const brand = data.brands[key]
                    return (

                        <div key={i}
                             className={`flex flex-col cursor-pointer relative border border-1 hover:border-primary hover:opacity-100 ${includes(box.brands, brand.key) ? 'border-primary' : 'opacity-90'}`}
                             onClick={() => handleClick(brand.key)}>
                            <div>
                                <img src={brand.image}/>
                            </div>

                            <div className="flex justify-between mt-2">
                                {includes(box.brands, brand.key) &&
                                <div className=" flex items-center justify-center absolute top-0 right-0 mt-2 mr-2 bg-primary rounded-full text-white" style={{width: 22, height: 22}}><i
                                    className="fas fa-check text-sm"></i></div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </WizardContainer>
    );

};

export default Brand;
