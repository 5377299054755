import React from 'react';
import {
    Link, useHistory
} from "react-router-dom";

const Header = () => {
    let history = useHistory();

    return (

        <header className="mb-10 " style={{height: 50}}>
            <div className="flex justify-between container mx-auto flex pl-5 pr-5 ">

                <div>
                    <img src={require('../assets/logo.png').default}
                         className="object-contain cursor-pointer"
                         style={{width: 75, height: 75}}
                         onClick={() => history.push("/")}

                    />
                </div>

                {/*<div className="flex justify-center items-center text-16 ">*/}

                {/*    <div className="ml-6">*/}
                {/*        <Link to="/in-a-box">Antero in box</Link>*/}
                {/*    </div>*/}
                {/*    <div className="ml-6">*/}
                {/*        <Link to="/contact">Contact</Link>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </header>

    );
};

export default Header;
