import {filter, map} from 'lodash';

const sizeGroup = {
    general: {
        key: 'general',
        label: 'maat',
        description: '',
    },
    'uni-size': {
        key: 'uni-size',
        label: 'Uni size',
        description: '',
    },
    'trousers-width': {
        key: 'trousers-width',
        label: 'broek maat',
        description: '',
    },
    'trousers-length':  {
        key: 'trousers-length',
        label: 'broek lengte',
        description: '',
    },
}

const sizes = {
    "uni-size": {
        key: 'uni-size',
        label: 'Uni size',
        description: '',
        image: '',
        group: sizeGroup['uni-size'].key,
    },
    "sm-m": {
        key: 'sm-m',
        label: 'Small - Medium',
        description: '',
        image: '',
        group: sizeGroup.general.key,
    },
    "m-l": {
        key: 'm-l',
        label: 'Medium - Large',
        description: '',
        image: '',
        group: sizeGroup.general.key,
    },
    "l-xl": {
        key: 'l-xl',
        label: 'Large - Xlarge',
        description: '',
        image: '',
        group: sizeGroup.general.key,
    },
    "xl-xxl": {
        key: 'xl-xxl',
        label: 'Xlarge - XXLarge',
        description: '',
        image: '',
        group: sizeGroup.general.key,
    },

    "28W": {
        key: '28W',
        label: '28W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "29W": {
        key: '29W',
        label: '29W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "30W": {
        key: '30W',
        label: '30W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "31W": {
        key: '31W',
        label: '31W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "32W": {
        key: '32W',
        label: '32W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "33W": {
        key: '33W',
        label: '33W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "34W": {
        key: '34W',
        label: '34W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },
    "36W": {
        key: '36W',
        label: '36W',
        description: '',
        image: '',
        group: sizeGroup['trousers-width'].key,
    },

    "32L": {
        key: '32L',
        label: '32L',
        description: '',
        image: '',
        group: sizeGroup['trousers-length'].key,
    },
    "34L": {
        key: '34L',
        label: '34L',
        description: '',
        image: '',
        group: sizeGroup['trousers-length'].key,
    },
}

const brands = {
    "bensherman": {
        key: 'bensherman',
        label: 'Ben Sherman',
        description: '',
        image: require('../assets/brands/bensherman.jpg').default,
    },
    "bjornborg": {
        key: 'bjornborg',
        label: 'Björn Borg',
        description: '',
        image: require('../assets/brands/bjornborg.jpg').default,
    },
    "blend": {
        key: 'blend',
        label: 'Blend',
        description: '',
        image: require('../assets/brands/blend.jpg').default,
    },
    "hazefinn": {
        key: 'hazefinn',
        label: 'Haze & Finn',
        description: '',
        image: require('../assets/brands/hazefinn.jpg').default,
    },
    "fredperry": {
        key: 'fredperry',
        label: 'Fred Perry',
        description: '',
        image: require('../assets/brands/fredperry.jpg').default,
    },
    "lylescott": {
        key: 'lylescott',
        label: 'Lyle & Scott',
        description: '',
        image: require('../assets/brands/lylescott.jpg').default,
    },
    "tommy": {
        key: 'tommy',
        label: 'Tommy Hilfiger',
        description: '',
        image: require('../assets/brands/tommy.jpg').default,
    },
    "diesel": {
        key: 'diesel',
        label: 'Diesel',
        description: '',
        image: require('../assets/brands/diesel.jpg').default,
    },
    "noexcess": {
        key: 'noexcess',
        label: 'No Excess',
        description: '',
        image: require('../assets/brands/noexcess.jpg').default,
    },
    "petrol": {
        key: 'petrol',
        label: 'Petrol Industries',
        description: '',
        image: require('../assets/brands/petrol.jpg').default,
    },
}

const types = {
    "outfits-lounge": {
        key: 'outfits-lounge',
        label: 'Complete Outfits',
        description: '',
        image: require('../assets/type/outfitslounge.jpg').default,
        brands: [brands.bjornborg.key, brands.blend.key, brands.hazefinn.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup.general.key || s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),
    },
    "outfits-sport": {
        key: 'outfits-sport',
        label: 'Complete Outfits',
        description: '',
        image: require('../assets/type/outfitssport.jpg').default,
        brands: [brands.bjornborg.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup.general.key || s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),
    },
    "outfits-casual": {
        key: 'outfits-casual',
        label: 'Complete Outfits',
        description: '',
        image: require('../assets/type/outfitscasual.jpg').default,
        brands: [brands.bensherman.key, brands.bjornborg.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup.general.key || s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),
    },
    "outfits-dressed": {
        key: 'outfits-dressed',
        label: 'Complete Outfits',
        description: '',
        image: require('../assets/type/outfitsdressed.jpg').default,
        brands: [brands.bensherman.key, brands.bjornborg.key, brands.blend.key, brands.hazefinn.key, brands.tommy.key, brands.diesel.key, brands.noexcess.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup.general.key || s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),
    },

    "trousers": {
        key: 'trousers',
        label: 'Broeken',
        description: '',
        image: require('../assets/type/trousers.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.tommy.key, brands.hazefinn.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),

    },
    "trousers-lounge": {
        key: 'trousers-lounge',
        label: 'Broeken',
        description: '',
        image: require('../assets/type/trousers-lounge.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup.general.key), (s) => s.key),

    },
    "jeans": {
        key: 'jeans',
        label: 'Jeans',
        description: '',
        image: require('../assets/type/jeans.jpg').default,
        brands: [brands.blend.key, brands.diesel.key],
        sizes: map(filter(sizes, (s) => s.group === sizeGroup['trousers-length'].key || s.group === sizeGroup['trousers-width'].key), (s) => s.key),
    },

    "shirts": {
        key: 'shirts',
        label: 'Hemden',
        description: '',
        image: require('../assets/type/shirts.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.noexcess.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "shirts-dressed": {
        key: 'shirts',
        label: 'Hemden',
        description: '',
        image: require('../assets/type/shirts-dressed.jpg').default,
        brands: [brands.bensherman.key, brands.hazefinn.key, brands.tommy.key, brands.diesel.key, brands.noexcess.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

    "tshirts": {
        key: 'tshirts',
        label: 'T-shirts',
        description: '',
        image: require('../assets/type/tshirts.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.noexcess.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "tshirts-dressed": {
        key: 'tshirts-dressed',
        label: 'T-shirts',
        description: '',
        image: require('../assets/type/tshirts.jpg').default,
        brands: [brands.bensherman.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "tshirts-lounge": {
        key: 'tshirts-lounge',
        label: 'T-shirts',
        description: '',
        image: require('../assets/type/tshirts-lounge.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key,  brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "tshirts-sport": {
        key: 'tshirts-sport',
        label: 'T-shirts',
        description: '',
        image:  require('../assets/type/tshirts-sport.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.noexcess.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "tshirts-with-print": {
        key: 'tshirts-with-print',
        label: 'Bedrukte t-shirts',
        description: '',
        image: require('../assets/type/tshirts-with-print.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

    "sweaters": {
        key: 'sweaters',
        label: 'Sweaters',
        description: '',
        image: require('../assets/type/sweaters.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "sweaters-lounge": {
        key: 'sweaters-lounge',
        label: 'Sweaters',
        description: '',
        image: require('../assets/type/sweaters-lounge.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "sweaters-sport": {
        key: 'sweaters-sport',
        label: 'Sweaters',
        description: '',
        image: require('../assets/type/sweaters-sport.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "sweater-winter": {
        key: 'sweater-winter',
        label: 'Knitwear',
        description: '',
        image: require('../assets/type/sweater-winter.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.noexcess.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "sweaters-with-roll": {
        key: 'sweaters-with-roll',
        label: 'Truien met rolkraag',
        description: '',
        image: require('../assets/type/sweaters-with-roll.jpg').default,
        brands: [brands.hazefinn.key, brands.lylescott.key, brands.noexcess.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

    "pullover": {
        key: 'pullover',
        label: 'Truien',
        description: '',
        image: require('../assets/type/pullover.jpg').default,
        brands: [brands.bensherman.key, brands.blend.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "pullover-dressed": {
        key: 'pullover-dressed',
        label: 'Truien',
        description: '',
        image: require('../assets/type/pullover-dressed.jpg').default,
        brands: [brands.bensherman.key, brands.noexcess.key, brands.hazefinn.key, brands.fredperry.key, brands.lylescott.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "pullover-sport": {
        key: 'pullover-sport',
        label: 'Truien',
        description: '',
        image: require('../assets/type/pullover-sport.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "hoodies-lounge": {
        key: 'hoodies-lounge',
        label: 'Sweaters met kap',
        description: '',
        image: require('../assets/type/hoodies-lounge.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "hoodies-sport": {
        key: 'hoodies-sport',
        label: 'Sweaters met kap',
        description: '',
        image: require('../assets/type/hoodies-sport.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.tommy.key, brands.diesel.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

    "stockings": {
        key: 'stockings',
        label: 'Kousen',
        description: '',
        image: require('../assets/type/stockings.jpg').default,
        brands: [brands.bjornborg.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "boxers": {
        key: 'boxers',
        label: 'Boxershorts',
        description: '',
        image: require('../assets/type/boxers.jpg').default,
        brands: [brands.bjornborg.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "polos": {
        key: 'polos',
        label: 'Polo\'s',
        description: '',
        image: require('../assets/type/polos.jpg').default,
        brands: [brands.blend.key, brands.fredperry.key, brands.lylescott.key, brands.tommy.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "jackets": {
        key: 'jackets',
        label: 'Jassen',
        description: '',
        image: require('../assets/type/jackets.jpg').default,
        brands: [brands.blend.key, brands.hazefinn.key, brands.lylescott.key, brands.petrol.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },
    "items-branded": {
        key: 'items-branded',
        label: 'Artikelen met logo',
        description: '',
        image: require('../assets/type/items-branded.JPG').default,
        brands: [brands.fredperry.key, brands.lylescott.key, brands.tommy.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

    "cap": {
        key: 'cap',
        label: 'Pet',
        description: '',
        image: require('../assets/type/cap.jpg').default,
        brands: [brands.hazefinn.key],
        sizes: map(filter(sizes, (s) => s.group  === sizeGroup.general.key), (s) => s.key),
    },

}

const styles = {
    "lounge": {
        key: 'lounge',
        label: 'Vrije tijd',
        description: '',
        image: require('../assets/style/lounge.jpg').default,
        types: [types["outfits-lounge"].key, types["tshirts-lounge"].key, types['tshirts-with-print'].key, types["trousers-lounge"].key, types["sweaters-lounge"].key, types["hoodies-lounge"].key, types.stockings.key, types.boxers.key, types.cap.key],
        typesDislike: [types['sweater-winter'].key, types['sweaters-with-roll'].key, types['items-branded'].key],
        priority: 0,
    },
    "sport": {
        key: 'sport',
        label: 'Sportief',
        description: '',
        image: require('../assets/style/sport.jpg').default,
        types: [types['outfits-sport'].key, types.shirts.key, types["tshirts-sport"].key, types['tshirts-with-print'].key, types["sweaters-sport"].key, types["pullover-sport"].key, types["hoodies-sport"].key, types.stockings.key, types.boxers.key, types.polos.key, types.jackets.key, types.jeans.key, types.cap.key],
        typesDislike: [types['sweater-winter'].key, types['sweaters-with-roll'].key, types['items-branded'].key],
        priority: 1,
    },
    "casual": {
        key: 'casual',
        label: 'Casual',
        description: '',
        image: require('../assets/style/casual.jpg').default,
        types: [types['outfits-casual'].key, types.shirts.key, types.tshirts.key, types['tshirts-with-print'].key, types.sweaters.key,types['sweater-winter'].key, types['sweaters-with-roll'].key, types.pullover.key, types.trousers.key, types.stockings.key, types.boxers.key, types.polos.key, types.jackets.key, types.jeans.key, types.cap.key],
        typesDislike: [types['sweater-winter'].key, types['sweaters-with-roll'].key, types['items-branded'].key],
        priority: 2,
    },
    "dressed": {
        key: 'dressed',
        label: 'Gekleed',
        description: '',
        image: require('../assets/style/dressed.jpg').default,
        types: [types['outfits-dressed'].key, types["shirts-dressed"].key, types["tshirts-dressed"].key, types['sweater-winter'].key, types['sweaters-with-roll'].key, types["pullover-dressed"].key, types.trousers.key, types.stockings.key, types.boxers.key, types.polos.key, types.jackets.key, types.jeans.key],
        typesDislike: [types['sweater-winter'].key, types['sweaters-with-roll'].key, types['items-branded'].key],
        priority: 3,
    }
}

const ages = {
    "18-30": {
        key: '18-30',
        label: '18 – 30 jaar',
        description: '',
        image: '',
        priority: 0,
    },
    "31-40": {
        key: '31-40',
        label: '31 – 40 jaar',
        description: '',
        image: '',
        priority: 1,
    },
    "41-60": {
        key: '41-60',
        label: '41 – 60 jaar',
        description: '',
        image: '',
        priority: 2,
    },
    "60": {
        key: '60',
        label: '+ 60 jaar',
        description: '',
        image: '',
        priority: 3,
    },
};

const boxSizes = {
    "small": {
        key: 'small',
        label: 'Klein',
        description: '',
        image: '',
    },

    "large": {
        key: 'large',
        label: 'Groot',
        description: '',
        image: '',
    },
    "king": {
        key: 'king',
        label: '2 complete outfits',
        description: '',
        image: '',
    },
}

const anteroBox = {
    styles: [
        {...styles.casual},
        {...styles.lounge},
        {...styles.dressed},
        {...styles.sport},

    ],

    ages: Object.keys(ages),
    boxSizes: Object.keys(boxSizes),
}

export default  {
    sizeGroup,
    sizes,
    brands,
    types,
    styles,
    ages,
    boxSizes,
    configuration: anteroBox,
}
