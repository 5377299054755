import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {includes, map, orderBy} from "lodash";
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const Age = () => {
    const {box, data, assignBoxAge} = useContext(DataContext);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Age'
        });
    }, []);

    const handleClick = (key) => {
        assignBoxAge(key)
    }

    const validator = () => {
        if (box.brands.length === 0) {
            setErrors("Gelieve een leeftijdscategorie te selecteren")
        }

        return box.brands.length > 0
    }

    const options = map(data.configuration.ages, (key) => data.ages[key])
    const sortedOptions = orderBy(options, ['priority'], ['asc'])

    return (
        <WizardContainer title="Welke leeftijd ben je?" errors={errors} validator={validator}>
            <div className=" grid grid-cols-2  md:grid-cols-4 gap-2 md:gap-4">
                {map(sortedOptions, (age, i) => {
                    return (
                        <div key={i}
                             className={`flex relative cursor-pointer items-center justify-center hover:border-primary border truncate ${(box.age === age.key) ? 'border-primary' : ""} border-1 p-2`}
                             style={{minHeight: 50}}
                             onClick={() => handleClick(age.key)}>
                            {age.label}

                            <div className="flex justify-between mt-2">
                                {(box.age === age.key) &&
                                <div className=" flex items-center justify-center absolute top-0 right-0 mt-2 mr-2 bg-primary rounded-full text-white" style={{width: 22, height: 22}}><i
                                    className="fas fa-check text-sm"></i></div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </WizardContainer>

    );


};

export default Age;
