import React, {useContext, useEffect, useState} from 'react';
import TextField from "./TextField";
import DataContext from "../../../data/context";
import Select from "react-select";
import {map, orderBy} from "lodash";

import WizardContainer from "./WizardContainer";
import {hasValue, isValidEmail} from "../../../validators";
import {useHistory} from "react-router-dom";
import SelectBox from "./SelectBox";
import ContentPopup from "./ContentPopup";
import ReactGA from "react-ga";

const Identification = () => {
    const {box, assignBoxContactField, sendMail} = useContext(DataContext);
    const [errors, setErrors] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    let history = useHistory();

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Identification'
        });
    }, []);

    const setField = (field, value) => {
        assignBoxContactField(field, value)
    }

    const validator = () => {
        let errors = {};

        if (!hasValue(box.contact.firstName)) {
            errors['firstName'] = "Gelieve uw voornaam in te vullen."
        }

        if (!hasValue(box.contact.lastName)) {
            errors['lastName'] = "Gelieve uw naam in te vullen."
        }

        if (!hasValue(box.contact.email)) {
            errors['email'] = "Gelive uw e-mailadres in te vullen"
        } else if (!isValidEmail(box.contact.email)) {
            errors['email'] = "Ongeldig e-mailadres";
        }

        if (!hasValue(box.contact.telephone)) {
            errors['telephone'] = "Gelieve uw telefoonnummer in te vullen"
        }

        if (!hasValue(box.contact.street)) {
            errors['street'] = "Gelieve uw straat in te vullen"
        }

        if (!hasValue(box.contact.number)) {
            errors['number'] = "Nummer"
        }

        if (!hasValue(box.contact.city)) {
            errors['city'] = "Gelieve uw gemeente te selecteren"
        }

        if (!termsAccepted) {
            errors['terms'] = "Gelieve de algemene voorwaarden te aanvaarden"
        }

        setErrors(errors);

        return Object.keys(errors).length <= 0;
    }

    const findSelectOption = (options, value) => {
        return options.find((opt) => opt.value === value);
    };

    const cityOptions = [
        {
            label: "Avelgem",
            value: 'avelgem',
        },
        {
            label: "Kruishoutem",
            value: 'kruishoutem',
        },
        {
            label: "Oudenaarde",
            value: 'oudenaarde',
        },
        {
            label: "Zingem",
            value: 'zingem',
        },
        {
            label: "Horebeke",
            value: 'horebeke',
        },
        {
            label: "Zottegem",
            value: 'zottegem',
        },
        {
            label: "Zwalm",
            value: 'zwalm',
        },
        {
            label: "Brakel",
            value: 'brakel',
        },
        {
            label: "Maarkedal",
            value: 'maarkedal',
        },
    ];
    const completed = (result) => {
        if (result === 'ok') {
            history.push({pathname: 'box-complete'});
        }
    }


    return (
        <WizardContainer errors="" validator={validator} onComplete={() => {
            sendMail(completed)

        }}>
            <div className="flex flex-col mt-6">

                <div className="flex flex-col md:flex-row justify-center">
                   <div className="hidden md:flex md:flex-col md:w-1/2 bg-gray-200 p-8 md:mr-8">
                       <h2 className="text-primary">We zijn er bijna!</h2>
                       <div>Op basis van deze gegevens nemen wij contact op met jou en kunnen we beginnen met het samenstellen van u gepersonaliseerde box!</div>

                       <div className="font-bold mt-8 mb-2"> Alles op een rijtje:</div>
                       <div><i className="fa fa-check text-12 mr-2"/>Persoonlijke levering op een afgesproken tijdstip</div>
                       <div><i className="fa fa-check text-12 mr-2"/>30 min pas tijd bij jou thuis</div>
                       <div><i className="fa fa-check text-12 mr-2"/>Geen service-, verzend-, of abonnementskost</div>
                       <div><i className="fa fa-check text-12 mr-2"/>Enkel betalen voor de artikelen die goed bevonden worden</div>
                       <div><i className="fa fa-check text-12 mr-2"/>Betaling enkel cash of payconiq</div>

                       <h2 className="mt-10">Bedankt om lokaal te shoppen!</h2>
                   </div>

                    <div className=" md:w-1/2 pt-4 pb-4">

                        <h2 className="mb-6">Leveringsadres</h2>

                        <div className="mb-6">
                            <div className=" mb-6">
                                <label className={`block uppercase tracking-wide text-14 font-bold mb-1 text-color_one tracking-widest`}>
                                    Gemeente
                                </label>
                            </div>
                            <Select
                                value={findSelectOption(cityOptions, box.contact.city)}
                                options={orderBy(cityOptions, ['label'], ['asc'])}
                                isLoading={false}
                                onChange={(option) => {
                                    setField('city', option.value);
                                }}
                                placeholder=" Gemeente"
                                className=" cursor-pointer"
                                classNamePrefix=" SelectField"
                                isSearchable={false}
                            />
                        </div>
                        <div className=" mb-6 flex flex items-end">
                            <div className=" flex flex-col flex-1 mr-2">
                                <TextField label=" Straat"
                                           placeholder=" Straat"
                                           value={box.contact.street}
                                           onChange={(value) => setField('street', value)}
                                           error={errors['street']}
                                />
                            </div>

                            <div className="" style={{width: 80}}>
                                <TextField placeholder=" nr"
                                           value={box.contact.number}
                                           onChange={(value) => setField('number', value)}
                                           error={errors['number']}
                                />
                            </div>
                        </div>

                        <hr className=" solid border-color-five mt-6 mb-6"/>

                        <h2 className="mb-6">Contactinformatie</h2>
                        <div className=" mb-6">

                            <TextField label=" Voornaam"
                                       placeholder=" Voornaam"
                                       value={box.contact.firstName}
                                       onChange={(value) => setField('firstName', value)}
                                       error={errors['firstName']}
                            />
                        </div>

                        <div className=" mb-6">
                            <TextField label=" Naam"
                                       placeholder=" Naam"
                                       value={box.contact.lastName}
                                       onChange={(value) => setField('lastName', value)}
                                       error={errors['lastName']}
                            />
                        </div>

                        <div className=" mb-6">
                            <TextField label=" E-mailadres"
                                       placeholder="Email"
                                       value={box.contact.email}
                                       onChange={(value) => setField('email', value)}
                                       error={errors['email']}
                            />
                        </div>

                        <div className=" mb-6">
                            <TextField label=" Telefoonnummer"
                                       placeholder=" Telefoonnummer"
                                       value={box.contact.telephone}
                                       onChange={(value) => setField('telephone', value)}
                                       error={errors['telephone']}
                            />
                        </div>



                        <div className=" mb-6 flex">
                            <SelectBox description="" selected={termsAccepted} onChange={(value) => setTermsAccepted(value)}/>
                            <div className={`cursor-pointer ${errors['terms'] ? 'text-red-800' : ''} text-14  ml-5`}
                                 onClick={() => setTermsModalVisible(true)}>
                                Ik ga akkoord met de <span className="font-bold underline">algemene voorwaarden</span>
                            </div>
                        </div>


                    </div>


                </div>


            </div>

            {termsModalVisible &&
            <ContentPopup type="privacy_policy"
                          onClose={() => setTermsModalVisible(false)}
            />}
        </WizardContainer>
    );
};

export default Identification;
