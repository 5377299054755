import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {filter, flatten, forEach, groupBy, includes, intersection, keys, map, some, uniq} from "lodash";
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const Size = () => {
    const {box, data, assignBoxSize, removeBoxSize} = useContext(DataContext);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Size'
        });
    }, []);


    const sizeOptionsGrouped = groupBy(map(uniq(flatten(map(box.types, (selectedType) => {
        const configType = data.types[selectedType];
        return configType.sizes
    }))), (s) => data.sizes[s]), 'group');

    const validator = () => {
        let valid = true;
        const groupKeys = keys(sizeOptionsGrouped);
        forEach(groupKeys, (key) => {
            const group = data.sizeGroup[key];
            const keys = map(sizeOptionsGrouped[key], (i) => i.key);
            if (!some(intersection(keys, box.sizes))) {
                valid = false;
                return false;
            }
        })

        if (!valid) {
            setErrors("Gelieve uw maten te selecteren")
        }

        return valid
    }

    const handleClick = (key) => {
        if (!includes(box.sizes, key)) {
            const sizesForGroup = map(filter(data.sizes, (s) => s.group === data.sizes[key].group), (i) => i.key);
            const hasOtherValueForGroup = some(intersection(sizesForGroup, box.sizes));

            if (hasOtherValueForGroup) {
                const otherKey = intersection(sizesForGroup, box.sizes)[0];
                removeBoxSize(otherKey)
            }
            assignBoxSize(key)


        } else {
            removeBoxSize(key)
        }
    }

    return (
        <WizardContainer errors={errors} validator={validator}>

            {map(keys(sizeOptionsGrouped), (groupKey) => {
                const group = data.sizeGroup[groupKey];
                return (
                    <div className="mb-8">
                        <h2 className="mb-6 text-lg">Welke {group.label} heb je?</h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            {map(sizeOptionsGrouped[groupKey], (size, i) => {
                                return (
                                    <div key={i}
                                         className={`flex relative cursor-pointer items-center justify-center hover:border-primary border truncate ${includes(box.sizes, size.key) ? 'border-primary' : ""} border-1 p-2`}
                                         style={{minHeight: 50}}
                                         onClick={() => handleClick(size.key)}>
                                        {size.label}

                                        <div className="flex justify-between mt-2">
                                            {includes(box.sizes, size.key) &&
                                            <div className=" flex items-center justify-center absolute top-0 right-0 mt-2 mr-2 bg-primary rounded-full text-white" style={{width: 22, height: 22}}><i
                                                className="fas fa-check text-sm"></i></div>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}

        </WizardContainer>
    );
};

export default Size;
