import React from 'react';

const BoxComplete = () => {
    return (
        <div className="mt-4">


            <div className="flex-1 overflow-y-auto p-5 container mx-auto hidden md:flex">
                <div className="flex flex-col items-center justify-center mt-12 mb-8">
                    <h2 className="font-bold text-primary mb-4">Bedankt!</h2>
                    <h2 className="font-bold text-primary">Uw aanvraag is verzonden.</h2>
                </div>

                <div className="flex flex-col items-center">
                    <p className="leading-8 text-center">Controleer uw mailbox voor een bevestiginsmail.</p>
                </div>
            </div>

            <div className="flex flex-col bg-gray-200 p-8 md:mr-8 md:hidden">
                <h2 className="text-primary">We zijn er bijna!</h2>
                <div>Op basis van deze gegevens nemen wij contact op met jou en kunnen we beginnen met het samenstellen van u gepersonaliseerde box!</div>

                <div className="font-bold mt-8 mb-2"> Alles op een rijtje:</div>
                <div><i className="fa fa-check text-12 mr-2"/>Persoonlijke levering op een afgesproken tijdstip</div>
                <div><i className="fa fa-check text-12 mr-2"/>30 min pas tijd bij jou thuis</div>
                <div><i className="fa fa-check text-12 mr-2"/>Geen service-, verzend-, of abonnementskost</div>
                <div><i className="fa fa-check text-12 mr-2"/>Enkel betalen voor de artikelen die goed bevonden worden</div>
                <div><i className="fa fa-check text-12 mr-2"/>Betaling enkel cash of payconiq</div>

                <h2 className="mt-10">Bedankt om lokaal te shoppen!</h2>
            </div>
        </div>
    );
};

export default BoxComplete;
