import React from 'react';
import Terms from "../../Terms";

const ContentPopup = ({onClose}) => {
    return (
        <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.6)"}}>
            <div className="p-5 flex flex-col border-2 rounded-lg bg-white overflow-hidden" style={{width: '75%', height: '75%'}}>


                <div className="flex items-end justify-end mb-6" onClick={onClose}>
                    <i className="fa fa-times text-24 cursor-pointer"/>
                </div>

                <div className="flex flex-1 overflow-y-auto">
                   <Terms />
                </div>


            </div>
        </div>
    );
};

export default ContentPopup;
