import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga";

const Box = () => {
    let history = useHistory();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="mt-4">

            <div className="hidden md:block">
                <img src={require('../assets/header.jpg').default} className="object-contain cursor-pointer" onClick={() => history.push('/stel-jouw-box-samen')}/>
            </div>

            <div className="md:hidden">
                <img src={require('../assets/header-mobile.jpg').default} className="object-contain cursor-pointer" onClick={() => history.push('/stel-jouw-box-samen')}/>
            </div>

            <div className="container mx-auto">

                <div className="md:hidden flex flex-col items-center justify-center mt-4 mb-8">
                    <h2 className="font-bold text-primary">Winkelen in eigen omgeving!</h2>
                    <h3 className="font-secondary text-center">Laat ons een gepersonalisserde selectie voor je samen stellen.</h3>
                </div>


                <div className="hidden md:flex flex-col items-center justify-center  mt-4 md:mt-12 md:mb-8">
                    <h2 className="font-bold text-primary">Hoe werkt het?</h2>
                </div>


                <div className="mb-12">
                    <div className="md:grid  md:grid-cols-3 gap-4">
                        <div className=" md:border-r  flex md:flex-col md:pt-8 md:pb-8 pl-4 pr-4 mb-8 md:mb-0 ">
                            <div className="flex md:justify-center md:mb-4 mr-6">
                                <i className="fas fa-tasks text-40"></i>
                            </div>

                            <div>
                                <div className="flex md:text-center md:mb-2">
                                    <h3 className="mb-0">Vertel ons naar wat je op zoek bent</h3>
                                </div>
                                <div className="font-secondary">Op basis hiervan stellen wij de ideale box samen naar jouw smaak, stijl en budget</div>
                            </div>
                        </div>

                        <div className=" md:border-r  flex md:flex-col md:pt-8 md:pb-8 pl-4 pr-4 mb-8 md:mb-0">
                            <div className="flex md:justify-center md:mb-4 mr-6">
                                <i className="fas fa-phone text-40"></i>
                            </div>

                            <div>
                                <div className="flex md:text-center md:mb-2">
                                    <h3 className="mb-0">Na telefonische afspraak komen wij persoonlijk bij u langs *</h3>
                                </div>
                                <div className="font-secondary">30 minuten tijd om thuis te passen in uw vertrouwde omgeving</div>
                            </div>
                        </div>

                        <div className=" flex md:flex-col md:pt-8 md:pb-8 pl-4">
                            <div className="flex md:justify-center md:mb-4 mr-6">
                                <i className="fas fa-euro-sign text-40"></i>
                            </div>

                            <div>
                                <div className="flex md:text-center md:mb-2">
                                    <h3 className="mb-0">Enkel betalen waarvan je 100% overtuigd bent **</h3>
                                </div>
                                <div className="font-secondary">Geen verzendkosten, geen retourkosten & geen verplichtingen</div>
                            </div>

                        </div>


                    </div>


                    <div className="mt-4 pl-4 pr-4 text-12">
                        <div>
                            * Momenteel enkel beschikbaar in volgende gemeentes: Avelgem, Brakel, Horebeke, Kruishoutem, Maarkedal, Oudenaarde, Zingem, Zottegem, Zwalm.
                        </div>

                        <div>
                            ** Betaling kan enkel cash of via payconiq
                        </div>
                    </div>

                    <div className="flex flex-1 justify-center items-center mt-6 ">
                        <div className="flex bg-primary text-white font-primary font-bold font-22 px-4 py-4 cursor-pointer" onClick={() => history.push('/stel-jouw-box-samen')}>
                            Stel nu uw box samen!
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default Box;
