import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {map} from "lodash";
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const BoxSize = () => {
    const {data, box, assignBoxBoxSize} = useContext(DataContext);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        ReactGA.event({
            category: 'Box',
            action: 'Box-Size'
        });
    }, []);

    const handleClick = (key) => {
        assignBoxBoxSize(key)
    }

    const validator = () => {
        if (box.brands.length === 0) {
            setErrors("Gelieve een box size te selecteren")
        }

        return box.brands.length > 0
    }

    return (
        <WizardContainer title="Hoe groot mag je persoonlijk samengestelde box zijn?" errors={errors} validator={validator}>
            <div className=" grid grid-cols-2  md:grid-cols-4 gap-2 md:gap-4">
                {map(data.configuration.boxSizes, (key, i) => {
                    const boxSize = data.boxSizes[key]
                    return (
                        <div key={i}
                             className={`flex relative cursor-pointer items-center justify-center hover:border-primary border truncate ${(box.boxSize === boxSize.key) ? 'border-primary' : ""} border-1 p-2`}
                             style={{minHeight: 50}}
                             onClick={() => handleClick(boxSize.key)}>
                            {boxSize.label}

                            <div className="flex justify-between mt-2">
                                {(box.boxSize === boxSize.key) &&
                                <div className=" flex items-center justify-center absolute top-0 right-0 mt-2 mr-2 bg-primary rounded-full text-white" style={{width: 22, height: 22}}><i
                                    className="fas fa-check text-sm"></i></div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </WizardContainer>
    );


};

export default BoxSize;
