import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {includes, keys, map, orderBy} from 'lodash';
import WizardButtons from "./WizardButtons";
import WizardContainer from "./WizardContainer";
import ReactGA from "react-ga";

const images = require.context("../../../assets/style", true);

const Style = () => {
    const {box, data, assignBoxStyle, removeBoxStyle} = useContext(DataContext);
    const [errors, setErrors] = useState("");

    useEffect(() => {

        ReactGA.event({
            category: 'Box',
            action: 'Style'
        });
    }, []);

    const handleClick = (key) => {
        if (!includes(box.styles, key)) {
            assignBoxStyle(key)
        } else {
            removeBoxStyle(key)
        }
    }

    const validator = () => {
        let error = "";

        if (box.styles.length === 0) {
            error = "Gelieve ten minste 1 stijl te selecteren"
        }

        if (box.styles.length > 2) {
            error = "Gelieve het aantal stijlen te beperken tot maximum 2"
        }

        if (error !== "") {
            setErrors(error)
        }

        return (error === "")
    }

    const orderedStyles = orderBy(data.configuration.styles, ['priority'], ['asc'])

    return (
        <WizardContainer title="Welke stijl zoek je?" errors={errors} validator={validator}>
            <div className=" grid grid-cols-2  md:grid-cols-5 gap-2 md:gap-4">
                {map(orderedStyles, (style, i) => {
                    return (
                        <div key={i}
                             className={`md:w-auto flex-col cursor-pointer relative border border-1 hover:border-primary hover:opacity-100 ${includes(box.styles, style.key) ? 'border-primary' : 'opacity-90'}`}
                             onClick={() => handleClick(style.key)}>
                            <div>
                                <img src={style.image}/>
                            </div>
                            <div className={` flex bg-primary items-center justify-center text-white p-2 font-bold truncate`}>  {style.label}</div>

                            {includes(box.styles, style.key) &&
                            <div className="flex  items-center justify-center absolute  top-0 right-0 mt-2 mr-2 bg-primary border border-white rounded-full text-white "
                                 style={{width: 30, height: 30}}>
                                <i
                                    className="fas fa-check" style={{fontSize: 14}}></i></div>}
                        </div>
                    )
                })}
            </div>
        </WizardContainer>
    );
};

export default Style;
