import React from 'react';
import WizardButtons from "./WizardButtons";
import Scrollbar from "../../Scrollbar";

const WizardContainer = ({title, errors, validator, onComplete, children}) => {
    return (
        <div>
            {title &&
            <h2 className="mb-6 text-lg">{title}</h2>}


            <div style={{height: window.innerHeight - 225}}>
                <Scrollbar>
                    {children}
                </Scrollbar>
            </div>


            <div className="flex justify-between">

                <div className="flex mt-2 mb-2 text-red-500 mr-6 justify-center items-center">
                    {errors}
                </div>

                <WizardButtons validator={validator} onComplete={onComplete}/>
            </div>
        </div>
    );
};

export default WizardContainer;
