import React from "react";
import Header from "./compontents/Header";
import Footer from "./compontents/Footer";
import Contact from "./pages/Contact";
import Box from "./pages/Box";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {init} from 'emailjs-com';
import BoxComplete from "./pages/BoxComplete";

import CreateYourBox from "./pages/CreateYourBox";
import {mailJsUserId} from "./config";
import Terms from "./compontents/Terms";


init(mailJsUserId);




function App() {
    return (
        <Router>
            <div className="flex flex-col h-full font-body ">

                <Header/>


                <Switch>
                    <Route path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/stel-jouw-box-samen">
                        <CreateYourBox/>
                    </Route>
                    <Route path="/in-a-box">
                        <Box/>
                    </Route>
                    <Route path="/box-complete">
                        <BoxComplete/>
                    </Route>
                    <Route path="/algemene-voorwaarden">
                        <div className="container mx-auto">
                            <Terms/>
                        </div>
                    </Route>

                    <Route path="/">
                        {/*<Home/>*/}
                        <Box/>
                    </Route>
                </Switch>


                <Footer/>
            </div>


        </Router>
    );
}

export default App;
