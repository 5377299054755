import React, {useEffect} from 'react';
import AnteroBoxWizard from "../compontents/antero-box";
import ReactGA from "react-ga";

const CreateYourBox = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="flex-1 overflow-y-auto p-5 container mx-auto">
            <AnteroBoxWizard/>
        </div>
    );
};

export default CreateYourBox;
