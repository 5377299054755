import {filter} from "lodash";

export const actionTypes = {
    BOX_ASSIGN_STYLE: 'ANTERO_BOX_ASSIGN_STYLE',
    BOX_REMOVE_STYLE: 'ANTERO_BOX_REMOVE_STYLE',

    BOX_ASSIGN_TYPE: 'BOX_ASSIGN_TYPE',
    BOX_REMOVE_TYPE: 'BOX_REMOVE_TYPE',

    BOX_ASSIGN_TYPE_DISLIKE: 'BOX_ASSIGN_TYPE_DISLIKE',
    BOX_REMOVE_TYPE_DISLIKE: 'BOX_REMOVE_TYPE_DISLIKE',

    BOX_ASSIGN_BRAND: 'BOX_ASSIGN_BRAND',
    BOX_REMOVE_BRAND: 'BOX_REMOVE_BRAND',

    BOX_ASSIGN_AGE: 'BOX_ASSIGN_AGE',

    BOX_ASSIGN_SIZE: 'BOX_ASSIGN_SIZE',
    BOX_REMOVE_SIZE: 'BOX_REMOVE_SIZE',

    BOX_ASSIGN_BOX_SIZE: 'BOX_ASSIGN_BOX_SIZE',

    BOX_ASSIGN_CONTACT_FIELD: 'BOX_ASSIGN_CONTACT_FIELD',
};


export const dataReducer = (state, action) => {
    switch (action.type) {

        case actionTypes.BOX_ASSIGN_STYLE:
            return {
                ...state,
                styles: [...state.styles, action.payload.styleKey]
            };
        case actionTypes.BOX_REMOVE_STYLE:
            return {
                ...state,
                styles: filter(state.styles, (style) => style !== action.payload.styleKey)
            };
        case actionTypes.BOX_ASSIGN_TYPE:
            return {
                ...state,
                types: [...state.types, action.payload.typeKey]
            };
        case actionTypes.BOX_REMOVE_TYPE:
            return {
                ...state,
                types: filter(state.types, (type) => type !== action.payload.typeKey)
            };
        case actionTypes.BOX_ASSIGN_TYPE_DISLIKE:
            return {
                ...state,
                typesDislike: [...state.typesDislike, action.payload.typeKey]
            };
        case actionTypes.BOX_REMOVE_TYPE_DISLIKE:
            return {
                ...state,
                typesDislike: filter(state.typesDislike, (type) => type !== action.payload.typeKey)
            };
        case actionTypes.BOX_ASSIGN_BRAND:
            return {
                ...state,
                brands: [...state.brands, action.payload.brandKey]
            };
        case actionTypes.BOX_REMOVE_BRAND:
            return {
                ...state,
                brands: filter(state.brands, (brand) => brand !== action.payload.brandKey)
            };
        case actionTypes.BOX_ASSIGN_AGE:
            return {
                ...state,
                age: action.payload.ageKey,
            };
        case actionTypes.BOX_ASSIGN_SIZE:
            return {
                ...state,
                sizes: [...state.sizes, action.payload.sizeKey]
            };
        case actionTypes.BOX_REMOVE_SIZE:
            return {
                ...state,
                sizes: filter(state.sizes, (size) => size !== action.payload.sizeKey)
            };
        case actionTypes.BOX_ASSIGN_BOX_SIZE:
            return {
                ...state,
                boxSize: action.payload.boxSizeKey,
            };
        case actionTypes.BOX_ASSIGN_CONTACT_FIELD:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    [action.payload.field]: action.payload.value
                },
            };
        default:
            throw new Error();
    }
};
