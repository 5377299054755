import {actionTypes} from './reducers'


export const boxAssignStyleHandler = (dispatch, styleKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_STYLE,
        payload: {
            styleKey
        }
    });
};

export const boxRemoveStyleHandler = (dispatch, styleKey) => {
    dispatch({
        type: actionTypes.BOX_REMOVE_STYLE,
        payload: {
            styleKey
        }
    });
};


export const boxAssignTypeHandler = (dispatch, typeKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_TYPE,
        payload: {
            typeKey
        }
    });
};

export const boxRemoveTypeHandler = (dispatch, typeKey) => {
    dispatch({
        type: actionTypes.BOX_REMOVE_TYPE,
        payload: {
            typeKey
        }
    });
};

export const boxAssignTypeDislikeHandler = (dispatch, typeKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_TYPE_DISLIKE,
        payload: {
            typeKey
        }
    });
};

export const boxRemoveTypeDislikeHandler = (dispatch, typeKey) => {
    dispatch({
        type: actionTypes.BOX_REMOVE_TYPE_DISLIKE,
        payload: {
            typeKey
        }
    });
};


export const boxAssignBrandHandler = (dispatch, brandKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_BRAND,
        payload: {
            brandKey
        }
    });
};

export const boxRemoveBrandHandler = (dispatch, brandKey) => {
    dispatch({
        type: actionTypes.BOX_REMOVE_BRAND,
        payload: {
            brandKey
        }
    });
};

export const boxAssignAgeHandler = (dispatch, ageKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_AGE,
        payload: {
            ageKey
        }
    });
};

export const boxAssignSizeHandler = (dispatch, sizeKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_SIZE,
        payload: {
            sizeKey
        }
    });
};

export const boxRemoveSizeHandler = (dispatch, sizeKey) => {
    dispatch({
        type: actionTypes.BOX_REMOVE_SIZE,
        payload: {
            sizeKey
        }
    });
};

export const boxAssignBoxSizeHandler = (dispatch, boxSizeKey) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_BOX_SIZE,
        payload: {
            boxSizeKey
        }
    });
};

export const boxAssignBoxContactFieldHandler = (dispatch, field, value) => {
    dispatch({
        type: actionTypes.BOX_ASSIGN_CONTACT_FIELD,
        payload: {
            field,
            value
        }
    });
};

