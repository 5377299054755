import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DataContextProvider} from "./data/context";
import ReactGA from "react-ga";
import {hotjar} from "react-hotjar";

ReactGA.initialize('UA-59948889-1');
hotjar.initialize(2097431, 6);

ReactDOM.render(

        <DataContextProvider>
            <App/>
        </DataContextProvider>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
