import React from 'react';


const Contact = () => {
    return (
        <div>
            <div className="bg-red-300">
                MAP
            </div>

            <div className="flex flex-1 overflow-y-auto p-5 container mx-auto">
                <div className="flex flex-1 flex-col mt-12 mb-8">
                    <div className="font-bold text-primary">Adres</div>
                    <div>
                        <div>Krekelput 1</div>
                        <div>9700 Oudenaarde</div>
                        <div>Email: info@antero.be</div>
                    </div>
                </div>

                <div className="flex flex-1 flex-col mt-12 mb-8">
                    <div className="font-bold text-primary">Openingsuren</div>
                    <div>MA Gesloten</div>
                    <div>DI 14u – 20u (Avondshopping)</div>
                    <div>WO 10u –18u30</div>
                    <div>DO 10u –18u30</div>
                    <div>VR 10u –18u30</div>
                    <div>ZA 10u –18u30</div>
                    <div>ZO Gesloten</div>
                </div>

            </div>
        </div>
    );
};

export default Contact;
